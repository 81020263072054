<template>
  <UiTwoColLayout :sticky-start="false">
    <template #start>
      <SharedPortfolioWidgetExpandAssetList
        v-if="bp.isMobile.value"
        :data="fundsList"
        :loading="inv.page.isLoading"
        :currentParamId="widgetConfig.currentId"
      />

      <PageProfileMyInvAssetsInformation v-else no-grid />
    </template>

    <template #end>
      <SharedPortfolioWidget :config="widgetConfig">
        <template v-slot:[`slotOne`]>
          <UiCard
            v-if="isAllFunds"
            body-padding="none"
            :title="$t('my_inv.portfolio_composition.title')"
            :key="isAllFunds ? 'all-funds' : fundsList[0].id + 'composition'"
            :classes="{ header: { root: 'pb-0' } }"
          >
            <PageAssetsDetailsList
              :list="compositionChartData"
              light-title
              :class="{ 'mt-4': !bp.isMobile.value }"
              column
              :classes="{
                positiveNegative: {
                  span: 'text-primary font-semibold',
                },
              }"
            />
          </UiCard>

          <UiCard
            v-else-if="inv.portfolio.currMappedFund || (bp.isMobile.value && currentFundBalance.length)"
            body-padding="default"
            :classes="{ header: { root: 'pb-0' } }"
            :loading="widgetConfig.loading"
          >
            <PageAssetsListItem
              v-if="!bp.isMobile.value"
              :asset="inv.portfolio.currMappedFund"
              :use-info-min-height="false"
              fit-info-content
              hide-chip
              :paddingX="false"
            />
            <PageAssetsDetailsList
              :list="currentFundBalance"
              light-title
              :class="{ 'mt-4': !bp.isMobile.value }"
              column
              no-padding
            />
          </UiCard>
        </template>
      </SharedPortfolioWidget>
    </template>
  </UiTwoColLayout>
</template>

<script lang="ts">
import { mapState } from "pinia"
import { useMyInvestmentsStore } from "~/composables/store/my-investments"

import { AssetsDetail } from "@finq/portal/types"

import { Widgets } from "../../../../../../../packages/portfolio-widget/mixins/widget-components"

export default defineNuxtComponent({
  inject: ["inv"],

  setup() {
    definePageMeta({
      breadcrumb_items: ["profile-investments", "profile-investments-funds-id"],
    })

    const localePath = useLocalePath()
    const bp = useDisplay()
    const { locale } = useI18nUtils()

    return { localePath, bp, locale }
  },

  methods: {
    onSwiperSlideChange(index: number) {
      if (index === 0) {
        return this.$router.replace(this.localePath({ name: "profile-investments-funds-id" })).catch(() => {})
      }

      const nextFund = this.inv.portfolio.funds[index].id

      if (
        nextFund === this.inv.page.currentId ||
        isNullOrUndefined(nextFund) ||
        isNullOrUndefined(this.inv.page.currentId)
      ) {
        return
      }

      this.$router
        .replace(
          this.localePath({
            name: "profile-investments-funds-id",
            params: { id: nextFund },
          })
        )
        .catch(() => {})
    },
  },

  computed: {
    currentFundBalance() {
      const { balance = {}, assetPercentage = 0 } = this.inv.portfolio.currentFund || {}

      return [
        {
          title: this.$t("my_inv.balance.percentage"),
          value: toPercent(assetPercentage || 0, true, 2),
          suffix: "%",
        },
        {
          title: this.$t("my_inv.balance.funds_units"),
          value: balance.units || 0,
        },
        {
          title: this.$t("my_inv.balance.total_value"),
          value: toCash(balance.value || 0),
          suffix: "₪",
        },
        {
          title: this.$t("my_inv.balance.total_profit_loss"),
          value: balance.profitValue || 0,
          text: [
            toCash(balance.profitValue || 0),
            `(${toPercent(balance.profitPercentage || 0, false, 2)})`,
          ].join(" "),
        },
      ]
    },

    additionalData() {
      const current = this.inv.portfolio.currentActive

      return [
        {
          name: this.$t("my_inv.additional_data.avg_fund_size"),
          value: current.fundSize || current.assetSize,
        },
        {
          name: this.$t("my_inv.additional_data.avg_management_fee"),
          percentage: current.managementFee,
        },
      ]
    },

    isAllFunds() {
      return isNullOrUndefined(this.$route.params.id === "" ? null : this.$route.params.id)
    },

    compositionChartData() {
      return this.assetsDetails?.map((details: AssetsDetail) => ({
        id: details.asset.id,
        text: toPercent(details.assetPercentage, true, 0),
        value: details.assetPercentage,
        title: this.locale(details.asset, "name"),
        // subtitle: this.locale(details.asset, "fundHouse"),
      }))
    },

    widgetConfig() {
      const { isAssetView } = this.inv.page

      const isActivePortfolio = this.inv.portfolio.portfolio?.proposalType === "A"

      return {
        portfolio: this.inv.portfolio.portfolio,
        currentActive: this.inv.portfolio.currentActive,

        components: [
          ["slotOne", Widgets.Allocations],
          Widgets.Performance,
          Widgets.Holdings,
          Widgets.Geographical,
          Widgets.Sectors,
          [Widgets.AdditionalData, Widgets.CurrencyExposure],
        ],

        showOnlyIf: [
          {
            condition: isAssetView,
            components: [Widgets.Performance, Widgets.CurrencyExposure],
          },
          {
            condition: isActivePortfolio,
            components: [Widgets.Holdings, Widgets.Geographical, Widgets.Sectors],
          },
        ],

        isAssetView: isAssetView,
        currentId: this.inv.page.currentId,
        loading: this.inv.page.isLoading,
      }
    },

    fundsList() {
      return this.inv.portfolio?.funds || []
    },

    ...mapState(useMyInvestmentsStore, {
      showBy: (state) => state.showBy,
      findAssetById: (state) => state.findAssetById,
      assetsDetails: "getPortfolioAssets",
    }),
  },
})
</script>

<style lang="scss" scoped>
.dc-list {
  &--item {
    display: flex;
    flex-wrap: wrap;
    gap: theme.$spacing-xs;
  }
}
</style>
