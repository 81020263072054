<template>
  <SharedPortfolioStoreLayout :config="layoutProps" :_can-init="canInit">
    <!-- Empty body -->
  </SharedPortfolioStoreLayout>
</template>

<script lang="ts">
import { defineNuxtComponent } from "nuxt/app"

import { mapState } from "pinia"

import { Favorites } from "@finq/portal/mixins/favorites"
import { PortfolioData } from "@finq/portfolios/types"

import { StoreLayoutManagerProps } from "../../../portal/types/provideInjectSymbols"

export default defineNuxtComponent({
  name: "PortfoliosStore",

  setup() {
    definePageMeta({
      metaData: "meta.portfolios.store",
      breadcrumb_items: [],
    })

    const user = useUser()
    const { locale } = useI18nUtils()

    return { user, locale }
  },

  mixins: [
    Favorites,
    // SignupWithPlan({ planProdCode: ProdCodesEnum.Invest }),
    // DynamicRouteGuard({
    //   routeLeave: false,
    //   mustLogin: true,
    //   mustHaveRiskLevel: true,
    //   allowMarketingUser: true,
    // }),
  ],

  data: () => ({
    canInit: false,
    didInit: false,
    disabledPage: false,
  }),

  computed: {
    layoutProps() {
      const props: StoreLayoutManagerProps = {
        localeKey: "portfolios.store",

        page: {
          api: {
            method: PortfolioService.getPortfoliosSelections,
            params: {
              matchPercentage: "0.75,1.0",
            },
          },

          getBreadcrumbs: this.dynamicBreadcrumbsTitle,
        },

        routes: {
          lobby: "portfolios-store",
          details: "portfolios-store-details-pid-fid",
        },
      }

      return props
    },

    ...mapState(usePortfoliosStore, {
      getPortfolioById: (state) => state.getPortfolioById,
    }),
  },

  methods: {
    dynamicBreadcrumbsTitle(routeName: RoutesNamesList) {
      switch (routeName) {
        case "portfolios-lobby":
          return this.$t("meta.portfolios.lobby.breadcrumb")

        case "portfolios-store":
          return null

        case "portfolios-store-details-pid-fid":
          return this.locale(
            this.getPortfolioById((this.$route.params as any)?.pid) as PortfolioData,
            "portfolioName"
          )
      }
    },
  },
})
</script>
