<template>
  <PortalLayout :key="uniqUserKey" :config="layoutProps" :plan-prod-code="ProdCodesEnum.Fund">
    <template #under-header>
      <PortalShowBy v-model="showByModel" :config="showByConfig" />
      <PortalShowBy v-model="showByClassificationModel" :config="showByClassificationConfig" />
    </template>

    <NuxtPage />
  </PortalLayout>
</template>

<script lang="ts">
import { mapActions, mapState } from "pinia"

import { ShowByOption } from "@finq/portal/components/portal/ShowBy.vue"

import { FundsService } from "../composables/services/funds.service"
import { Pages as PagesEnum, Wrapper, useFundsPortalManager } from "../mixins/funds-portal-manager"
import { useFundsTableConfig } from "../mixins/funds-table-config"

type PageEnumValue = Exclude<
  (typeof PagesEnum)[keyof typeof PagesEnum],
  "default" | "funds_categories_classified" | "funds-search" | "funds-categories-id"
>
type PagesEnum = Record<PageEnumValue, any>

export default defineComponent({
  name: "Funds",

  setup() {
    definePageMeta({
      metaData: "meta.funds",
      page_title: "common.main",
      layoutProps: {
        hideFooter: true,
        backButton: true,
      },
    })
    const bp = useDisplay()
    const fundsTableConfig = useFundsTableConfig()
    const portalBus = usePortalEventBus()
    const fundsManager = useFundsPortalManager()
    const { tmsafe, locale } = useI18nUtils()
    const { uniqUserKey } = useUniqUserKey()

    const showByModel = toRef(useFundsStore().showBy)
    const showByClassificationModel = toRef(useFundsStore().showByClassification)

    return {
      fundsTableConfig,
      portalBus,
      fundsManager,
      bp,
      tmsafe,
      locale,
      uniqUserKey,
      showByModel,
      showByClassificationModel,
    }
  },

  computed: {
    currentResolvedRoute(): string {
      const { isManager, isCategories } = this.$route.meta

      if (isManager || isCategories) {
        return this.$route.name as string
      }

      return this.$route.fullPath.replace(/(en|he)\//gi, "")
    },

    prefetchConfig() {
      // Used in PrefetchFundsWrapper
      return {
        [Wrapper.Categories]: {
          endpoint: FundsService.getCategories,
          setData: this.setCategories,
          data: this.categories,
        },

        [Wrapper.FundsManager]: {
          endpoint: FundsService.getHouses,
          setData: this.setFundHouses,
          data: this.fundHouses,
        },
      }
    },

    layoutProps() {
      return {
        localeKey: "funds",
        filter: { showBy: this.showBy },

        search: {
          route: { name: "funds-search", searchQuery: "nameEn" },
          endpoint: (v: string, c: KyOptions) => FundsService.search(v, c),
          listItemTitleField: "fundHouse",
          listItemSubField: "name",
          useLocale: true,
        },

        tableConfig: this.fundsTableConfig,

        page: {
          props: this.fundsManager.pageProps.value,
          enum: PagesEnum,
          api: { method: FundsService.getFunds },
          prefetch: this.prefetchConfig,
          getBreadcrumbs: this.dynamicBreadcrumbsTitle,
        },

        globalParams: {
          sortBy: this.showBy,
          classification: this.showByClassification,
          order: "ASC",
        },
      }
    },

    showByConfig() {
      return {
        title: this.bp.isMobile.value ? this.$t("my_inv.show_by.title") : this.$t("funds.show_by.title"),
        options: Object.values(this.tmsafe("funds.show_by.options")) as ShowByOption[],
        value: this.showBy,
        setValue: this.updateShowBy,
        defaultValue: this.showBy,
      }
    },

    showByClassificationConfig() {
      return {
        title: this.$t("funds.classification_show_by_title"),
        options: Object.values(this.tmsafe("funds.classifications")).map((classification: any) => ({
          text: classification.title,
          value: classification.code,
        })),
        value: this.showByClassification,
        defaultValue: this.showByClassification,
        setValue: this.updateClassification,
      }
    },

    ...mapState(useFundsStore, ["showBy", "showByClassification", "categories", "fundHouses", "currentFund"]),
  },
  methods: {
    ...mapActions(useFundsStore, [
      "setCategories",
      "setFundHouses",
      "setShowBy",
      "setShowByClassification",
      "resetStore",
      "getFundHouseById",
      "getFromCategories",
    ]),

    async updateShowBy(value: string) {
      if (value === this.showBy) return
      this.setShowBy(value)
      this.portalBus.ResetSort.emit()
      this.portalBus.RefetchData.emit()
    },

    async updateClassification(value: string) {
      if (value === this.showByClassification) return
      this.setShowByClassification(value)
      this.portalBus.RefetchData.emit()
    },

    dynamicBreadcrumbsTitle(routeName: PageEnumValue) {
      const fundType = this.$route.params?.id as string
      const subCategory = this.$route.params?.subId as string
      const secondaryClassification = this.$route.params?.secondaryId as string
      const classification = this.showByClassification

      const currentFundHouse = this.getFundHouseById(fundType ?? this.fundHouses?.[0]?.id)

      const currentCategory = this.getFromCategories(fundType, subCategory)

      // if the current page is category list, and current category does not have more than one sub category,
      // then we want to hide the category name from the breadcrumbs
      const hideSubCategory =
        isRouteName(PagesEnum.CategoryList, this.$route.name) &&
        currentCategory?.secondaryClassifications.length === 1

      const titlesMap: PagesEnum = {
        // "funds-categories-id": this.locale(this.getFromCategories(fundType), "description"),

        "funds-categories-list-id-subId-secondaryId": this.locale(
          this.getFromCategories(fundType, subCategory, secondaryClassification),
          "description"
        ),

        "funds-categories-id-sub-subId": hideSubCategory
          ? false
          : this.locale(currentCategory, "description"),

        "funds-manager-id-list": this.$t(`funds.classifications.${classification}.named_title`, {
          name: this.locale(currentFundHouse, "fundHouse"),
        }),

        "funds-list": this.$t(`funds.classifications.${classification}.title`),
        "funds-details-id":
          !this.currentFund?.fundHouse || !this.currentFund.name
            ? ""
            : this.locale(this.currentFund, "fundHouse") + " - " + this.locale(this.currentFund),
      }

      return titlesMap[routeName] ?? null
    },
  },

  unmounted() {
    this.resetStore()
  },
})
</script>

<style lang="scss">
.fund-disclaimer-banner {
  margin-block-end: theme.$spacing-l;

  &__text {
    margin: 10px 0 0 !important;
  }
}
</style>
