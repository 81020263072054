
// @ts-nocheck


export const localeCodes =  [
  "he",
  "en"
]

export const localeLoaders = {
  "he": [{ key: "../../base/src/locales/he/common/accessibility.json", load: () => import("../../base/src/locales/he/common/accessibility.json" /* webpackChunkName: "locale__usr_src_apps_base_src_locales_he_common_accessibility_json" */), cache: true },
{ key: "../../base/src/locales/he/common/errors.json", load: () => import("../../base/src/locales/he/common/errors.json" /* webpackChunkName: "locale__usr_src_apps_base_src_locales_he_common_errors_json" */), cache: true },
{ key: "../../base/src/locales/he/common/meta.json", load: () => import("../../base/src/locales/he/common/meta.json" /* webpackChunkName: "locale__usr_src_apps_base_src_locales_he_common_meta_json" */), cache: true },
{ key: "../../base/src/locales/he/common/common.json", load: () => import("../../base/src/locales/he/common/common.json" /* webpackChunkName: "locale__usr_src_apps_base_src_locales_he_common_common_json" */), cache: true },
{ key: "../../base/src/locales/he/common/authentication.json", load: () => import("../../base/src/locales/he/common/authentication.json" /* webpackChunkName: "locale__usr_src_apps_base_src_locales_he_common_authentication_json" */), cache: true },
{ key: "../../base/src/locales/he/profile/profile_page.json", load: () => import("../../base/src/locales/he/profile/profile_page.json" /* webpackChunkName: "locale__usr_src_apps_base_src_locales_he_profile_profile_page_json" */), cache: true },
{ key: "../../base/src/locales/he/proposals/investments.json", load: () => import("../../base/src/locales/he/proposals/investments.json" /* webpackChunkName: "locale__usr_src_apps_base_src_locales_he_proposals_investments_json" */), cache: true },
{ key: "../src/locales/he/profile/my_inv.json", load: () => import("../src/locales/he/profile/my_inv.json" /* webpackChunkName: "locale__usr_src_apps_finq_finance_src_locales_he_profile_my_inv_json" */), cache: true },
{ key: "../../../packages/ui/locales/he/common/common.json", load: () => import("../../../packages/ui/locales/he/common/common.json" /* webpackChunkName: "locale__usr_src_packages_ui_locales_he_common_common_json" */), cache: true },
{ key: "../../../packages/kyc/locales/he/kyc.json", load: () => import("../../../packages/kyc/locales/he/kyc.json" /* webpackChunkName: "locale__usr_src_packages_kyc_locales_he_kyc_json" */), cache: true },
{ key: "../../../packages/funds/locales/he/funds.json", load: () => import("../../../packages/funds/locales/he/funds.json" /* webpackChunkName: "locale__usr_src_packages_funds_locales_he_funds_json" */), cache: true },
{ key: "../../../packages/stocks/locales/he/stocks.json", load: () => import("../../../packages/stocks/locales/he/stocks.json" /* webpackChunkName: "locale__usr_src_packages_stocks_locales_he_stocks_json" */), cache: true },
{ key: "../../../packages/portfolios/locales/he/portfolios.json", load: () => import("../../../packages/portfolios/locales/he/portfolios.json" /* webpackChunkName: "locale__usr_src_packages_portfolios_locales_he_portfolios_json" */), cache: true },
{ key: "../../../packages/portfolio-widget/locales/he/portfolio_widget.json", load: () => import("../../../packages/portfolio-widget/locales/he/portfolio_widget.json" /* webpackChunkName: "locale__usr_src_packages_portfolio_widget_locales_he_portfolio_widget_json" */), cache: true },
{ key: "../../../packages/pension/locales/he/pension_depot.json", load: () => import("../../../packages/pension/locales/he/pension_depot.json" /* webpackChunkName: "locale__usr_src_packages_pension_locales_he_pension_depot_json" */), cache: true }],
  "en": [{ key: "../../base/src/locales/en/common/accessibility.json", load: () => import("../../base/src/locales/en/common/accessibility.json" /* webpackChunkName: "locale__usr_src_apps_base_src_locales_en_common_accessibility_json" */), cache: true },
{ key: "../../base/src/locales/en/common/errors.json", load: () => import("../../base/src/locales/en/common/errors.json" /* webpackChunkName: "locale__usr_src_apps_base_src_locales_en_common_errors_json" */), cache: true },
{ key: "../../base/src/locales/en/common/meta.json", load: () => import("../../base/src/locales/en/common/meta.json" /* webpackChunkName: "locale__usr_src_apps_base_src_locales_en_common_meta_json" */), cache: true },
{ key: "../../base/src/locales/en/common/common.json", load: () => import("../../base/src/locales/en/common/common.json" /* webpackChunkName: "locale__usr_src_apps_base_src_locales_en_common_common_json" */), cache: true },
{ key: "../../base/src/locales/en/common/authentication.json", load: () => import("../../base/src/locales/en/common/authentication.json" /* webpackChunkName: "locale__usr_src_apps_base_src_locales_en_common_authentication_json" */), cache: true },
{ key: "../../base/src/locales/en/profile/profile_page.json", load: () => import("../../base/src/locales/en/profile/profile_page.json" /* webpackChunkName: "locale__usr_src_apps_base_src_locales_en_profile_profile_page_json" */), cache: true },
{ key: "../../base/src/locales/en/proposals/investments.json", load: () => import("../../base/src/locales/en/proposals/investments.json" /* webpackChunkName: "locale__usr_src_apps_base_src_locales_en_proposals_investments_json" */), cache: true },
{ key: "../../../packages/ui/locales/en/common/common.json", load: () => import("../../../packages/ui/locales/en/common/common.json" /* webpackChunkName: "locale__usr_src_packages_ui_locales_en_common_common_json" */), cache: true },
{ key: "../../../packages/kyc/locales/en/kyc.json", load: () => import("../../../packages/kyc/locales/en/kyc.json" /* webpackChunkName: "locale__usr_src_packages_kyc_locales_en_kyc_json" */), cache: true },
{ key: "../../../packages/funds/locales/en/funds.json", load: () => import("../../../packages/funds/locales/en/funds.json" /* webpackChunkName: "locale__usr_src_packages_funds_locales_en_funds_json" */), cache: true },
{ key: "../../../packages/stocks/locales/en/stocks.json", load: () => import("../../../packages/stocks/locales/en/stocks.json" /* webpackChunkName: "locale__usr_src_packages_stocks_locales_en_stocks_json" */), cache: true },
{ key: "../../../packages/portfolios/locales/en/portfolios.json", load: () => import("../../../packages/portfolios/locales/en/portfolios.json" /* webpackChunkName: "locale__usr_src_packages_portfolios_locales_en_portfolios_json" */), cache: true },
{ key: "../../../packages/portfolio-widget/locales/en/portfolio_widget.json", load: () => import("../../../packages/portfolio-widget/locales/en/portfolio_widget.json" /* webpackChunkName: "locale__usr_src_packages_portfolio_widget_locales_en_portfolio_widget_json" */), cache: true },
{ key: "../../base/src/locales/en/common/accessibility.json", load: () => import("../../base/src/locales/en/common/accessibility.json" /* webpackChunkName: "locale__usr_src_apps_base_src_locales_en_common_accessibility_json" */), cache: true },
{ key: "../../base/src/locales/en/common/errors.json", load: () => import("../../base/src/locales/en/common/errors.json" /* webpackChunkName: "locale__usr_src_apps_base_src_locales_en_common_errors_json" */), cache: true },
{ key: "../../base/src/locales/en/common/meta.json", load: () => import("../../base/src/locales/en/common/meta.json" /* webpackChunkName: "locale__usr_src_apps_base_src_locales_en_common_meta_json" */), cache: true },
{ key: "../../base/src/locales/en/common/common.json", load: () => import("../../base/src/locales/en/common/common.json" /* webpackChunkName: "locale__usr_src_apps_base_src_locales_en_common_common_json" */), cache: true },
{ key: "../../base/src/locales/en/common/authentication.json", load: () => import("../../base/src/locales/en/common/authentication.json" /* webpackChunkName: "locale__usr_src_apps_base_src_locales_en_common_authentication_json" */), cache: true },
{ key: "../../base/src/locales/en/profile/profile_page.json", load: () => import("../../base/src/locales/en/profile/profile_page.json" /* webpackChunkName: "locale__usr_src_apps_base_src_locales_en_profile_profile_page_json" */), cache: true },
{ key: "../../base/src/locales/en/proposals/investments.json", load: () => import("../../base/src/locales/en/proposals/investments.json" /* webpackChunkName: "locale__usr_src_apps_base_src_locales_en_proposals_investments_json" */), cache: true },
{ key: "../src/locales/en/profile/my_inv.json", load: () => import("../src/locales/en/profile/my_inv.json" /* webpackChunkName: "locale__usr_src_apps_finq_finance_src_locales_en_profile_my_inv_json" */), cache: true },
{ key: "../../../packages/ui/locales/en/common/common.json", load: () => import("../../../packages/ui/locales/en/common/common.json" /* webpackChunkName: "locale__usr_src_packages_ui_locales_en_common_common_json" */), cache: true },
{ key: "../../../packages/kyc/locales/en/kyc.json", load: () => import("../../../packages/kyc/locales/en/kyc.json" /* webpackChunkName: "locale__usr_src_packages_kyc_locales_en_kyc_json" */), cache: true },
{ key: "../../../packages/funds/locales/en/funds.json", load: () => import("../../../packages/funds/locales/en/funds.json" /* webpackChunkName: "locale__usr_src_packages_funds_locales_en_funds_json" */), cache: true },
{ key: "../../../packages/stocks/locales/en/stocks.json", load: () => import("../../../packages/stocks/locales/en/stocks.json" /* webpackChunkName: "locale__usr_src_packages_stocks_locales_en_stocks_json" */), cache: true },
{ key: "../../../packages/portfolios/locales/en/portfolios.json", load: () => import("../../../packages/portfolios/locales/en/portfolios.json" /* webpackChunkName: "locale__usr_src_packages_portfolios_locales_en_portfolios_json" */), cache: true },
{ key: "../../../packages/portfolio-widget/locales/en/portfolio_widget.json", load: () => import("../../../packages/portfolio-widget/locales/en/portfolio_widget.json" /* webpackChunkName: "locale__usr_src_packages_portfolio_widget_locales_en_portfolio_widget_json" */), cache: true },
{ key: "../../../packages/pension/locales/en/pension_depot.json", load: () => import("../../../packages/pension/locales/en/pension_depot.json" /* webpackChunkName: "locale__usr_src_packages_pension_locales_en_pension_depot_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../../base/i18n.config.ts?hash=796ac88a&config=1" /* webpackChunkName: "i18n_config_796ac88a" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "he",
      "name": "Hebrew",
      "dir": "rtl",
      "files": [
        "/usr/src/apps/base/src/locales/he/common/accessibility.json",
        "/usr/src/apps/base/src/locales/he/common/errors.json",
        "/usr/src/apps/base/src/locales/he/common/meta.json",
        "/usr/src/apps/base/src/locales/he/common/common.json",
        "/usr/src/apps/base/src/locales/he/common/authentication.json",
        "/usr/src/apps/base/src/locales/he/profile/profile_page.json",
        "/usr/src/apps/base/src/locales/he/proposals/investments.json",
        "/usr/src/apps/finq-finance/src/locales/he/profile/my_inv.json",
        "/usr/src/packages/ui/locales/he/common/common.json",
        "/usr/src/packages/kyc/locales/he/kyc.json",
        "/usr/src/packages/funds/locales/he/funds.json",
        "/usr/src/packages/stocks/locales/he/stocks.json",
        "/usr/src/packages/portfolios/locales/he/portfolios.json",
        "/usr/src/packages/portfolio-widget/locales/he/portfolio_widget.json",
        "/usr/src/packages/pension/locales/he/pension_depot.json"
      ],
      "language": "he-IL"
    },
    {
      "code": "en",
      "name": "English",
      "dir": "ltr",
      "files": [
        "/usr/src/apps/base/src/locales/en/common/accessibility.json",
        "/usr/src/apps/base/src/locales/en/common/errors.json",
        "/usr/src/apps/base/src/locales/en/common/meta.json",
        "/usr/src/apps/base/src/locales/en/common/common.json",
        "/usr/src/apps/base/src/locales/en/common/authentication.json",
        "/usr/src/apps/base/src/locales/en/profile/profile_page.json",
        "/usr/src/apps/base/src/locales/en/proposals/investments.json",
        "/usr/src/packages/ui/locales/en/common/common.json",
        "/usr/src/packages/kyc/locales/en/kyc.json",
        "/usr/src/packages/funds/locales/en/funds.json",
        "/usr/src/packages/stocks/locales/en/stocks.json",
        "/usr/src/packages/portfolios/locales/en/portfolios.json",
        "/usr/src/packages/portfolio-widget/locales/en/portfolio_widget.json",
        "/usr/src/apps/base/src/locales/en/common/accessibility.json",
        "/usr/src/apps/base/src/locales/en/common/errors.json",
        "/usr/src/apps/base/src/locales/en/common/meta.json",
        "/usr/src/apps/base/src/locales/en/common/common.json",
        "/usr/src/apps/base/src/locales/en/common/authentication.json",
        "/usr/src/apps/base/src/locales/en/profile/profile_page.json",
        "/usr/src/apps/base/src/locales/en/proposals/investments.json",
        "/usr/src/apps/finq-finance/src/locales/en/profile/my_inv.json",
        "/usr/src/packages/ui/locales/en/common/common.json",
        "/usr/src/packages/kyc/locales/en/kyc.json",
        "/usr/src/packages/funds/locales/en/funds.json",
        "/usr/src/packages/stocks/locales/en/stocks.json",
        "/usr/src/packages/portfolios/locales/en/portfolios.json",
        "/usr/src/packages/portfolio-widget/locales/en/portfolio_widget.json",
        "/usr/src/packages/pension/locales/en/pension_depot.json"
      ],
      "language": "en-US"
    }
  ],
  "defaultLocale": "he",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": true,
  "langDir": "locales/",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "https://finqai.co.il",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "he",
    "name": "Hebrew",
    "dir": "rtl",
    "files": [
      {
        "path": "/usr/src/apps/base/src/locales/he/common/accessibility.json"
      },
      {
        "path": "/usr/src/apps/base/src/locales/he/common/errors.json"
      },
      {
        "path": "/usr/src/apps/base/src/locales/he/common/meta.json"
      },
      {
        "path": "/usr/src/apps/base/src/locales/he/common/common.json"
      },
      {
        "path": "/usr/src/apps/base/src/locales/he/common/authentication.json"
      },
      {
        "path": "/usr/src/apps/base/src/locales/he/profile/profile_page.json"
      },
      {
        "path": "/usr/src/apps/base/src/locales/he/proposals/investments.json"
      },
      {
        "path": "/usr/src/apps/finq-finance/src/locales/he/profile/my_inv.json"
      },
      {
        "path": "/usr/src/packages/ui/locales/he/common/common.json"
      },
      {
        "path": "/usr/src/packages/kyc/locales/he/kyc.json"
      },
      {
        "path": "/usr/src/packages/funds/locales/he/funds.json"
      },
      {
        "path": "/usr/src/packages/stocks/locales/he/stocks.json"
      },
      {
        "path": "/usr/src/packages/portfolios/locales/he/portfolios.json"
      },
      {
        "path": "/usr/src/packages/portfolio-widget/locales/he/portfolio_widget.json"
      },
      {
        "path": "/usr/src/packages/pension/locales/he/pension_depot.json"
      }
    ],
    "language": "he-IL"
  },
  {
    "code": "en",
    "name": "English",
    "dir": "ltr",
    "files": [
      {
        "path": "/usr/src/apps/base/src/locales/en/common/accessibility.json"
      },
      {
        "path": "/usr/src/apps/base/src/locales/en/common/errors.json"
      },
      {
        "path": "/usr/src/apps/base/src/locales/en/common/meta.json"
      },
      {
        "path": "/usr/src/apps/base/src/locales/en/common/common.json"
      },
      {
        "path": "/usr/src/apps/base/src/locales/en/common/authentication.json"
      },
      {
        "path": "/usr/src/apps/base/src/locales/en/profile/profile_page.json"
      },
      {
        "path": "/usr/src/apps/base/src/locales/en/proposals/investments.json"
      },
      {
        "path": "/usr/src/packages/ui/locales/en/common/common.json"
      },
      {
        "path": "/usr/src/packages/kyc/locales/en/kyc.json"
      },
      {
        "path": "/usr/src/packages/funds/locales/en/funds.json"
      },
      {
        "path": "/usr/src/packages/stocks/locales/en/stocks.json"
      },
      {
        "path": "/usr/src/packages/portfolios/locales/en/portfolios.json"
      },
      {
        "path": "/usr/src/packages/portfolio-widget/locales/en/portfolio_widget.json"
      },
      {
        "path": "/usr/src/apps/base/src/locales/en/common/accessibility.json"
      },
      {
        "path": "/usr/src/apps/base/src/locales/en/common/errors.json"
      },
      {
        "path": "/usr/src/apps/base/src/locales/en/common/meta.json"
      },
      {
        "path": "/usr/src/apps/base/src/locales/en/common/common.json"
      },
      {
        "path": "/usr/src/apps/base/src/locales/en/common/authentication.json"
      },
      {
        "path": "/usr/src/apps/base/src/locales/en/profile/profile_page.json"
      },
      {
        "path": "/usr/src/apps/base/src/locales/en/proposals/investments.json"
      },
      {
        "path": "/usr/src/apps/finq-finance/src/locales/en/profile/my_inv.json"
      },
      {
        "path": "/usr/src/packages/ui/locales/en/common/common.json"
      },
      {
        "path": "/usr/src/packages/kyc/locales/en/kyc.json"
      },
      {
        "path": "/usr/src/packages/funds/locales/en/funds.json"
      },
      {
        "path": "/usr/src/packages/stocks/locales/en/stocks.json"
      },
      {
        "path": "/usr/src/packages/portfolios/locales/en/portfolios.json"
      },
      {
        "path": "/usr/src/packages/portfolio-widget/locales/en/portfolio_widget.json"
      },
      {
        "path": "/usr/src/packages/pension/locales/en/pension_depot.json"
      }
    ],
    "language": "en-US"
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
