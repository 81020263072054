import type { RouteLocationNormalized } from "#vue-router"

/**
 * { redirect: { name: "stocks-portfolios___en" } }
 * Redirect inside middlewares is not working because of ___locale suffixes,
 *
 * this method is a workaround for that.
 */
export function Middleware_RedirectTo(
  redirectTo: RoutesNamesList,
  /** a callback method that returns a boolean if the client can access the route */
  condition: (to: RouteLocationNormalized<any>, from: RouteLocationNormalized) => boolean = () => false
) {
  return ((to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    // Quitting the middleware if the query param is present
    const redirectIf = condition?.(to, from)
    if (redirectIf === false) return

    // Navigating to the route
    return navigateTo({ name: getRouteNameWithLangSuffix(redirectTo) as any })
  }) as () => void
}
