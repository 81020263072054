<template>
  <div>
    <UiLoadingAnimation name="fade" leave-absolute :loading="kycManager.loading">
      <KycQuestionsFactory :questions="kycManager.currentGroup.questions" />
    </UiLoadingAnimation>
  </div>
</template>

<script lang="ts">
import { mapState } from "pinia"
import { isDevelopment } from "std-env"

import { useKycStore } from "../../../composables/store"
import { KycManagerProps } from "../../../types/provideInjectSymbols"
import { KycNavigationManager } from "../../../utils/Manager"
import { mockInvAnswers, paramsArrayToObject } from "../../../utils/index"

export default defineNuxtComponent({
  mixins: [KycNavigationManager],

  inject: ["kycManager"],

  setup() {
    definePageMeta({
      metaData: "meta.kyc.questionnaire_page",
      layoutProps: { hideNavbar: true, hideFooter: true },
    })

    const kycManager = inject("kycManager", {}) as KycManagerProps
    const route = useRoute()
    const kycStore = useKycStore()
    const {
      locale,
      i18n: { t },
    } = useI18nUtils({ useScope: "global" })

    // Adding debug option to add mock answers
    if (!isProd() && route.query.debug !== undefined) {
      kycStore.setAnswers({ ...mockInvAnswers(), ...kycStore.answers })
    }

    useSeo(
      computed(() => ({
        title: t("meta.kyc.questionnaire_page.title", {
          category: locale(kycManager?.currentGroup?.category, "description"),
        }),
      }))
    )

    return { locale }
  },

  computed: {
    ...mapState(useKycStore, {
      groups: (state) => state.groups,
    }),
  },

  watch: {
    async $route(to, from) {
      if (!isRouteName("kyc-type-group", to.name)) return

      // Scrolling to top on route change
      window.scrollTo(0, 0)

      const toParams = paramsArrayToObject(to)
      const fromParams = paramsArrayToObject(from)

      // Fetching the questionnaire again when on type change
      if (toParams.type !== fromParams.type) {
        await this.kycManager.fetchQuestionnaire()
      }

      // If only the group id changes, we update the current group id
      if (toParams?.group !== fromParams?.group) {
        this.setCurrentGroupId(toParams?.group)
      }
    },
  },

  // Not sure where it supposed to be
  // async onBeforeRouteUpdate(to: any, from: any, next: any) {
  //   // Redirect to first group guard
  //   // If user tries to remove the group id, we redirect to the first group to prevent errors because of unknown group id
  //   if (!to.params?.group && to.params.type === from?.params.type) {
  //     return next(this.genNavigationLink(this.groups[0].id));
  //   } else {
  //     next();
  //   }
  // },
})
</script>
