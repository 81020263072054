<template>
  <ClientOnly>
    <!-- // Desktop 'Product' SSR fallback button -->
    <template #fallback>
      <UiButton
        v-for="(action, index) in [...products.otherStart, ...products.otherEnd]"
        :class="cn('md:px-xs text-primary whitespace-pre px-0 py-0 leading-none')"
        :key="index"
        variant="list-item"
      >
        {{ action.label }}
      </UiButton>
      <UiButton class="md:px-xs text-primary whitespace-pre p-0 leading-none" variant="list-item">
        {{ t("products.invest.title") }}
      </UiButton>
    </template>

    <!-- // Mobile menu list items -->
    <template v-if="bp.isMobile.value">
      <li v-for="(action, index) in concatenatedProducts" :key="index">
        <UiButton
          :class="
            cn('md:px-xs text-primary whitespace-pre px-0 py-0 leading-none [&.active]:font-semibold', {
              'active bg-gray-50/50': isProductActive(concatenatedProducts, index),
            })
          "
          :id="genClickId('menu_link', index)"
          variant="list-item"
          :to="action?.to"
          @click="closeMenu"
        >
          {{ action.label }}
          <div v-if="action.isNew">
            <UiBadge :classes="{ root: cn('bg-badge-new text-badge-new-foreground rounded-full px-1.5') }">
              <span class="text-xxs leading-4">
                {{ t("new_badge") }}
              </span>
            </UiBadge>
          </div>
        </UiButton>
      </li>
    </template>

    <!-- // Desktop 'Product' menu popover -->
    <Fragment v-if="!bp.isMobile.value">
      <UiButton
        v-for="(action, index) in products.otherStart"
        :class="
          cn(
            'text-primary whitespace-pre px-0 py-0 leading-none hover:font-normal hover:text-black/50 [&.active]:font-semibold',
            {
              active: isProductActive(products.otherStart, index),
              'gap-xxxs': action.isNew,
            }
          )
        "
        :key="index"
        :id="genClickId('menu_link', index)"
        variant="list-item"
        :to="action?.to"
        @click="closeMenu"
      >
        {{ action.label }}
        <div v-if="action.isNew">
          <UiBadge :classes="{ root: cn('bg-badge-new text-badge-new-foreground rounded-full px-1.5') }">
            <span class="text-xxs leading-4">
              {{ t("new_badge") }}
            </span>
          </UiBadge>
        </div>
      </UiButton>

      <!-- Invest Products -->
      <UiNavigationMenu
        :classes="{
          itemLink: 'px-5 leading-6',
          trigger:
            'md:px-xs text-primary whitespace-pre px-0 py-0 leading-none hover:bg-transparent hover:font-normal focus:bg-transparent',
        }"
        :items="products.invest"
      >
        <template #trigger>
          <UiButton
            :class="
              cn(
                'md:px-xs text-primary hover:background-none whitespace-pre bg-transparent px-0 py-0 text-base font-normal leading-none hover:text-black/50 data-[state=open]:bg-transparent',
                { active: isProductActive(products.invest) }
              )
            "
            variant="list-item"
          >
            {{ t("products.invest.title") }}
          </UiButton>
        </template>
      </UiNavigationMenu>

      <UiButton
        v-for="(action, index) in products.otherEnd"
        :class="
          cn(
            'text-primary whitespace-pre px-0 py-0 leading-none hover:font-normal hover:text-black/50 [&.active]:font-semibold',
            {
              active: isProductActive(products.otherEnd, index),
              'gap-xxxs': action.isNew,
            }
          )
        "
        :key="index"
        :id="genClickId('menu_link_end', index)"
        variant="list-item"
        :to="action?.to"
        @click="closeMenu"
      >
        {{ action.label }}
        <div v-if="action.isNew">
          <UiBadge :classes="{ root: cn('bg-badge-new text-badge-new-foreground rounded-full px-1.5') }">
            <span class="text-xxs leading-4">
              {{ t("new_badge") }}
            </span>
          </UiBadge>
        </div>
      </UiButton>
    </Fragment>
  </ClientOnly>
</template>

<script setup lang="ts">
import { tempReplaceLinkIfOwned } from "@finq/app-base/utils/common/product-utils"

import { type PlanCode, PricingProductEnum } from "@finq/pricing/types"

type ProductNavigationMenuLink = {
  id: keyof typeof PricingProductEnum
  screenPath: string
  label: string
  planCode: PlanCode
  isNew?: boolean
}

type MappedProductNavigationMenuLink = Omit<ProductNavigationMenuLink, "id"> & {
  id: string
  to?: string
}

const bp = useDisplay()
const emit = defineEmits<{ (e: "menu:close"): void }>()
const { t } = useI18n({ useScope: "local" })
const { tmsafe } = useI18nUtils({ useScope: "local" })

const showMenu = ref<boolean>(false)
const user = useUser()
const router = useRouter()

const mapProducts = (products: ProductNavigationMenuLink[]): MappedProductNavigationMenuLink[] => {
  return products.map((product, index) => {
    const prodCode = PricingProductEnum[product.id]

    return {
      ...product,
      id: genClickId("menu_link", prodCode, index),
      to: tempReplaceLinkIfOwned({
        productPlan: {
          productId: prodCode,
          code: product.planCode,
          screenPath: product.screenPath,
        },
        isProductOwnedByUser: user.canAccessProduct(prodCode),
      }),
    }
  })
}

const products = computed(() => {
  return {
    invest: mapProducts(tmsafe<ProductNavigationMenuLink[]>("products.invest.links")),
    otherStart: mapProducts(tmsafe<ProductNavigationMenuLink[]>("products.links.start")),
    otherEnd: mapProducts(tmsafe<ProductNavigationMenuLink[]>("products.links.end")),
  }
})

const concatenatedProducts = computed(() => [
  ...products.value.otherStart,
  ...products.value.invest,
  ...products.value.otherEnd,
])

const isProductActive = (
  product: MappedProductNavigationMenuLink | MappedProductNavigationMenuLink[],
  index?: number
) => {
  if (product && Array.isArray(product)) {
    if (!isNullOrUndefined(index) && typeof index === "number") {
      return router.currentRoute.value.path.includes(product[index].screenPath)
    }

    return product.some((p) => router.currentRoute.value.path.includes(p.screenPath))
  }

  return router.currentRoute.value.path.includes(product?.screenPath)
}

const closeMenu = () => {
  showMenu.value = false
  emit("menu:close")
}
</script>

<i18n lang="json">
{
  "en": {
    "new_badge": "NEW",
    "products": {
      "links": {
        "start": [
          {
            "id": "Pensions",
            "label": "Pensions",
            "screenPath": "pension/finqfull"
          },
          {
            "id": "Stocks",
            "label": "Stocks",
            "screenPath": "stocks/depot"
          }
        ],
        "end": [
          {
            "id": "AIFund",
            "label": "AI S&P Fund",
            "screenPath": "kranot-gidur",
            "isNew": true
          }
        ]
      },
      "invest": {
        "title": "Mutual Funds",
        "links": [
          {
            "label": "Mutual Funds Portal",
            "id": "Funds",
            "screenPath": "funds",
            "planCode": "DIY"
          },
          {
            "id": "Funds",
            "label": "Investment Portfolio Management",
            "screenPath": "kyc/INV",
            "planCode": "MANAGED"
          }
        ]
      }
    }
  },
  "he": {
    "new_badge": "חדש",
    "products": {
      "links": {
        "start": [
          {
            "id": "Pensions",
            "label": "פנסיות",
            "screenPath": "pension/finqfull"
          },
          {
            "id": "Stocks",
            "label": "מניות",
            "screenPath": "stocks/depot"
          }
        ],
        "end": [
          {
            "id": "AIFund",
            "label": "קרן AI S&P",
            "screenPath": "kranot-gidur",
            "isNew": true
          }
        ]
      },
      "invest": {
        "title": "קרנות נאמנות",
        "links": [
          {
            "id": "Funds",
            "label": "פורטל קרנות נאמנות",
            "screenPath": "funds",
            "planCode": "DIY"
          },
          {
            "id": "Funds",
            "label": "ניהול תיק השקעות",
            "screenPath": "kyc/INV",
            "planCode": "MANAGED"
          }
        ]
      }
    }
  }
}
</i18n>
