<template>
  <div class="p-nav my-inv-container">
    <header class="container pt-6">
      <UiTabs
        :classes="{ root: 'pb-6', tab: 'w-full', tabWrapper: 'w-auto flex-1' }"
        :list="mappedNavTabs"
        :swiper="{ enabled: bp.isMobile.value }"
      >
        <template v-slot="{ isNotifications, label }">
          {{ label }}
          <div
            class="h-s w-s ms-1 rounded-full bg-[#eaebeb] text-center text-xs font-semibold leading-5 text-black"
            v-if="isNotifications"
          >
            {{ notifications?.unSeenCount }}
          </div>
        </template>
      </UiTabs>

      <PageProfileBreadcrumbs v-if="!bp.isMobile.value" />
      <div class="border-t pb-6" v-if="!bp.isMobile.value" />
    </header>

    <NuxtPage />
  </div>
</template>

<script lang="ts">
import { HTTPError } from "ky"
import { isEmpty } from "lodash-es"
import { mapActions, mapState } from "pinia"
import { useMyInvestmentsStore } from "~/composables/store/my-investments"

import { PortfolioData } from "@finq/portfolios/types"

import { PortfolioService } from "../../../../../packages/portfolios/composables/services/portfolio.service"

interface MyInvNavigation {
  label: string
  to: TypedRouteProps | string
}

export default defineComponent({
  name: "MyInvestmentsContainer",

  setup() {
    definePageMeta({
      page_title: "profile_page.sidebar.sidebar_tabs[1].title",
      metaData: "meta.my_inv",
      middleware: ["authenticated-or-login"],
    })
    const routeLocale = useLocaleRoute()
    const snackbar = useSnackbar()
    const { tmsafe, locale } = useI18nUtils()
    const bp = useDisplay()

    return { routeLocale, snackbar, tmsafe, locale, bp }
  },

  provide() {
    return {
      inv: reactiveComputed(() => ({
        portfolio: this.portfolioConfig,
        page: this.pageConfig,
      })),
    }
  },

  async created() {
    await Promise.all([this.fetchUserInvestments(), this.fetchUserNotifications()])
  },

  computed: {
    isActive() {
      const activeStatus = [AssetsStatusEnum.Active, AssetsStatusEnum.ActivePending].includes(
        this.portfolio?.portfolioStatus
      )

      return activeStatus && !!this.portfolio?.assetsDetails?.length
    },

    isActivePending() {
      const activePendingStatus = [AssetsStatusEnum.ActivePending, AssetsStatusEnum.Pending].includes(
        this.portfolio?.portfolioStatus
      )

      return activePendingStatus && !!this.portfolio?.assetsDetails?.length
    },

    portfolioConfig() {
      const stocks = this.allStocks.map(this.mapStockDataToList)
      const currentFund = this.findAssetById(this.$route.params.id)

      return {
        funds: this.mappedFundsList,
        stocks,
        portfolio: this.portfolio,
        currentFund,
        currMappedFund: this.mapFundDataToList(currentFund),
        currentActive: currentFund?.asset ?? this.portfolio,
        isSomeInProcess: this.getAssetStatus(AssetsStatusEnum.InProcess, "some"),
        isAllInProcess: this.getAssetStatus(AssetsStatusEnum.InProcess, "every"),
        isAllPending: this.getAssetStatus(AssetsStatusEnum.Pending, "every"),
      }
    },

    pageConfig() {
      const { isActive, isActivePending, isLoading } = this
      const activeOrPending = isActive ? "active" : "pending"
      const currentId = isLoading ? null : this.getParamId ?? activeOrPending

      return {
        currentId,
        isAssetView: currentId !== activeOrPending,
        isActive,
        isActivePending,
        isLoading,
      }
    },

    mappedNavTabs() {
      return (this.tmsafe("my_inv.navigation") as MyInvNavigation[]).map((item) => {
        // disable navigation item if some of the assets has inProcess status
        if (isRouteName("portfolios-store", this.$route.name) && this.portfolioConfig?.isSomeInProcess) {
          return {
            ...item,
            disabled: true,
            to: null,
          }
        }

        return item
      })
    },

    mappedFundsList() {
      return [
        {
          title: this.$t("my_inv.all_funds"),
          image: "all-funds-icon",
          chip: [AssetsStatusEnum.Pending, AssetsStatusEnum.ActivePending].includes(
            this.portfolio?.portfolioStatus
          ),
          balance: {
            value: {
              value: this.portfolio?.balance?.value,
              text: toCash(this.portfolio?.balance?.value),
              subtext: this.$t("my_inv.value"),
            },
          },
          to: this.routeLocale({ name: "profile-investments-funds-id" }),
          selected:
            isNullOrUndefined(this.getParamId) &&
            isRouteName("profile-investments-funds-id", this.$route.name),
        },
        ...this.allFunds.map(this.mapFundDataToList),
      ]
    },

    getParamId() {
      // id as default is empty string isNullOrUndefined method doesnt catch this exception
      return this.$route.params.id === "" ? null : this.$route.params.id
    },

    ...mapState(useMyInvestmentsStore, {
      portfolio: (state) => state.portfolio,
      notifications: (state) => state.notifications,
      isLoading: (state) => state.isLoading,
      allFunds: (state) => state.getFundsOnly,
      allStocks: (state) => state.getStocksOnly,
    }),
  },

  methods: {
    ...mapActions(useMyInvestmentsStore, {
      toggleIsLoading: "toggleIsLoading",
      setPortfolio: "setPortfolio",
      setNotifications: "setNotifications",
      findAssetById: "findAssetById",
    }),

    getAssetStatus(
      status: (typeof AssetsStatusEnum)[keyof typeof AssetsStatusEnum],
      arrayFunc: "every" | "some"
    ) {
      return this.portfolio?.assetsDetails[arrayFunc]((asset) => status === asset.status) ?? false
    },

    async fetchUserInvestments() {
      // Checking if portfolio is not already loaded. If it is, we don't need to fetch it again
      this.toggleIsLoading(true)

      try {
        const res = (await PortfolioService.getUserPortfolios()) as PortfolioData

        if (isNullOrUndefined(res)) this.portfolioNotFound = true
        else this.setPortfolio(res)
      } catch (err: unkown) {
        console.error(err)
        this.portfolioNotFound = true

        if (err instanceof HTTPError) {
          this.snackbar.open({
            type: "error",
            text: this.$t("my_inv.errors.loading_portfolio"),
            timeout: 5,
          })
        }
      } finally {
        this.toggleIsLoading(false)
      }
    },

    async fetchUserNotifications() {
      try {
        if (isEmpty(this.notifications)) {
          const res = await UserDocsService.getNotifications()

          this.setNotifications(res)
        }
      } catch (err) {
        console.log(err)
      }
    },

    mapBalanceToChip(balance) {
      if (isNullOrUndefined(balance)) return null

      const profitPercent = balance?.profitPercentage || 0
      const profitValue = balance?.profitValue || 0

      return {
        profitPercentage: {
          value: profitPercent || 0,
          text: toPercent(profitPercent || 0, false, 2),
        },
        profitValue: {
          value: profitValue || 0,
          text: toCash(profitValue || 0),
        },
        units: {
          value: balance.units || 0,
          subtext: this.$t("my_inv.units"),
        },
        value: {
          value: balance.value || 0,
          text: toCash(balance.value || 0),
          subtext: this.$t("my_inv.value"),
        },
      }
    },

    mapStockDataToList(data) {
      const { asset } = data

      return {
        id: asset.id,
        title: asset.symbol,
        subtitle: this.locale(asset),
        image: asset.stockLogo,
        status: data.status,
        balance: this.mapBalanceToChip(data.balance),
      }
    },

    mapFundDataToList(data) {
      if (!data) return
      const { asset } = data

      const isAssetSelected =
        isRouteName("profile-investments", this.$route.name) && this.$route.params.id
          ? null
          : this.$route.params.id === asset?.id

      return {
        id: asset.id,
        title: this.locale(asset, "fundHouse"),
        subtitle: this.locale(asset),
        image: asset.logo,
        status: data.status,
        balance: this.mapBalanceToChip(data.balance),
        to: this.routeLocale({ name: "profile-investments-funds-id", params: { id: asset.id } }),
        selected: isAssetSelected,
      }
    },
  },
})
</script>

<style lang="scss">
.my-inv {
  &-container {
    padding-bottom: theme.$spacing-xl;

    p {
      margin-bottom: unset;
    }

    a {
      text-decoration: none;
    }

    span {
      line-height: 1;
    }

    &--divider {
      border-top: 1px solid theme.$lighter-gray;
      padding-top: theme.$spacing-m;
    }
  }

  @include theme.media("<md") {
    &-container {
      overflow: hidden;
      padding-bottom: theme.$spacing-l;
    }
  }

  &--tabs.v-tabs {
    margin-bottom: unset;
  }

  &--crumbs {
    &.profile--breadcrumbs {
      margin-block-end: theme.$spacing-m;
    }
  }
}
</style>
