<template>
  <ClientOnly>
    <template #fallback>
      <UiSpinner size="xl" />
    </template>

    <div class="kyc-done-wrap">
      <component v-bind="getPageWithProps?.props" :is="getPageWithProps?.component" />
    </div>
  </ClientOnly>
</template>
<script lang="ts">
import { KycDynamicFinalScreen, KycFinalScreens } from "../../utils/Manager"

export default defineNuxtComponent({
  name: "KYCDone",
  mixins: [KycDynamicFinalScreen],
  setup() {
    definePageMeta({
      metaData: "meta.kyc.done",
      layoutProps: {
        hideNavbar: true,
        hideFooter: true,
      },
    })

    const { locale } = useI18nUtils()

    return { locale }
  },
  beforeRouteEnter(to, from, next) {
    if (!to.query.fs || !KycFinalScreens[to.query.fs as keyof typeof KycFinalScreens]) {
      next({ name: "pricing", replace: true })
    }
    next()
  },
})
</script>
<style></style>
