<template>
  <FundsByTypesPage :config="subCategoryConfig" />
</template>

<script lang="ts">
import { mapActions } from "pinia"

import { useFundsStore } from "../../../../../composables/store/funds"
import { Pages } from "../../../../../mixins/funds-portal-manager"

export default {
  name: "SubCategory",
  inject: ["layoutManager"],

  setup() {
    const { locale } = useI18nUtils()

    definePageMeta({ breadcrumb_items: ["funds-categories-id"] })

    return { locale }
  },

  methods: {
    ...mapActions(useFundsStore, ["getSubCategoryById"]),
  },

  computed: {
    subCategoryConfig() {
      return {
        selectedId: this.currentParamId,
        mainApiParameter: "secondaryClassification",
        displayTables: this.categoriesTables,
      }
    },

    currentParamId() {
      return this.$route.params.subId ?? this.subClassifications?.[0]?.id
    },

    categoriesTables() {
      return this.subClassifications?.map((secClass: { id: string }) => {
        return {
          title: this.locale(secClass, "description"),
          params: { secondaryClassification: secClass.id },
          moreLink: {
            name: Pages.CategoryList,
            params: {
              id: this.$route.params.id,
              subId: this.$route.params.subId,
              secondaryId: secClass.id,
            },
          },
        }
      })
    },

    subClassifications() {
      return this.currentSubCategory?.secondaryClassifications ?? []
    },

    currentSubCategory() {
      return this.getSubCategoryById(this.$route.params.id as string, this.$route.params.subId as string)
    },
  },
}
</script>
