<template>
  <UiNavigationMenuWrapper :class="classes?.wrapper">
    <UiNavigationMenuList>
      <UiNavigationMenuItem>
        <UiNavigationMenuTrigger :class="classes?.trigger" :withIcon as-child>
          <slot name="trigger" />
        </UiNavigationMenuTrigger>
        <UiNavigationMenuContent>
          <ul :class="cn('grid min-w-56 py-0.5 md:grid-cols-2', props?.classes?.itemsWrapper)">
            <li v-for="(item, index) in items" :key="`${item.label}-${index}`" :class="props?.classes?.item">
              <UiNavigationMenuLink as-child>
                <UiLink
                  :class="
                    cn(
                      'focus:bg-accent block select-none p-2 text-sm leading-none no-underline outline-none transition-colors hover:bg-neutral-50 focus:text-black',
                      props?.classes?.itemLink
                    )
                  "
                  :to="item.to"
                >
                  {{ item.label }}
                </UiLink>
              </UiNavigationMenuLink>
            </li>
          </ul>
        </UiNavigationMenuContent>
      </UiNavigationMenuItem>
    </UiNavigationMenuList>
  </UiNavigationMenuWrapper>
</template>

<script setup lang="ts">
type UiNavigationMenuProps = {
  items?: { id: string; label: string; to: string }[]
  classes?: Partial<{
    wrapper: ClassValue
    trigger: ClassValue
    content: ClassValue
    itemsWrapper: ClassValue
    item: ClassValue
    itemLink: ClassValue
  }>
  withIcon?: boolean
}

const props = defineProps<UiNavigationMenuProps>()
</script>
